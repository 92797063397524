<div class="wrapper">
    <div class="">
        <!-- START card-->
        <div class="input-group mb-4">

        </div>
        <div class="text-center mb-4">
            <h1 class="mb-3">
                <!-- <em class="fa fa-search fa-5x text-muted text-gray"></em> -->
                <img class="logo-padded" [src]="logoPath" alt="App Logo" />
            </h1>
            <div class="text-bold text-lg mb-3"><span style="text-transform: uppercase; font-size: 3rem;">Ops</span> 404</div>
            <p class="lead m0">{{'base.page_not_exist' | translate}}</p>
        </div>
        <!-- <ul class="list-inline text-center text-sm mb-4">
            <li class="list-inline-item">
                <a class="text-muted" [routerLink]="'/login'">Login</a>
            </li>
            <li class="text-muted list-inline-item">|</li>
            <li class="list-inline-item">
                <a class="text-muted" [routerLink]="'/register'">Register</a>
            </li>
        </ul> -->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <span class="mx-2">-</span>
            <span>V.{{ settings.getAppSetting('version') }}</span>
            <br/>
            <!-- <span>{{ settings.getAppSetting('description') }}</span> -->
        </div>
    </div>
</div>
